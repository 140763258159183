export const paginationContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexWrap: 'nowrap',
};

export const shownResultsIndicator = (theme) => ({
  color: theme.white,
  width: 80,
  textAlign: 'center',
  fontSize: 12,
  marginRight: 8,
});

export const paginationButton = (disabled, isActive) => (theme) =>
  [
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      userSelect: 'none',
      width: 32,
      height: 32,
      margin: '0 2px',
      borderRadius: '50%',
      color: theme.white,
      '&:hover': {
        backgroundColor: theme.newPrimaryBtn,
      },
    },
    isActive && {
      backgroundColor: theme.newPrimary,
      border: `2px solid ${theme.white}`,
    },
    disabled && {
      cursor: 'not-allowed',
      color: theme.white,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  ];
