export const bigHeadingHeader = (theme) => ({
  color: theme.white,
  fontSize: '2rem',
});

export const headerWrap = (theme) => ({
  backgroundColor: theme.newPrimary,
  '@media (max-width: 450px)': {
    padding: '2em',
  },
});

export const heading = (theme) => ({
  marginBottom: '1em',
  color: theme.newLavender,
  fontWeight: 300,
  fontFamily: 'Roboto, sans-serif;',
});
