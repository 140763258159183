import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { useAuth, Button, Modal } from 'components';
import Certificate from './Certificate';
import downloadIcn from 'assets/images/downloadIcn.svg';
import { certificateTabWrapper, certificateBtn, pdfViewerStyles, pdfViewerModalStyles, modalStyles } from './styles';

export const CertificateTab = ({ course, quizResultsPercentage }) => {
  const {
    user: { profile },
  } = useAuth();
  const { getText } = useTranslations();

  const [showModal, setShowModal] = useState(false);

  if (quizResultsPercentage < 75) {
    return (
      <div css={certificateTabWrapper}>
        <h6>{getText('courseCertificate')}</h6>
        <p>{getText('youDoNotHaveCertificateYet')}</p>
      </div>
    );
  }

  return (
    <>
      <div css={certificateTabWrapper}>
        <h6>{getText('courseCertificate')}</h6>
        <Button outlineInverse css={certificateBtn} onClick={() => setShowModal(true)}>
          <PDFViewer style={pdfViewerStyles} showToolbar={false}>
            <Certificate course={course} profile={profile} />
          </PDFViewer>
          <div className="overlay" />
        </Button>
      </div>
      <Modal show={showModal} onClose={() => setShowModal(false)} css={modalStyles}>
        <PDFViewer style={pdfViewerModalStyles} showToolbar={false}>
          <Certificate course={course} profile={profile} />
        </PDFViewer>
        <div className="downloadBtn-wrapper">
          <PDFDownloadLink
            document={<Certificate course={course} profile={profile} />}
            fileName={`${getText(course?.name)}_Certificate.pdf`}
            style={{ textDecoration: 'none' }}>
            {({ loading }) => (
              <Button outline>
                <img src={downloadIcn} alt="Download" style={{ width: 16, height: 16, marginRight: 8 }} />
                {loading ? getText('loadingDocument') : getText('download')}
              </Button>
            )}
          </PDFDownloadLink>
        </div>
      </Modal>
    </>
  );
};

CertificateTab.propTypes = {
  course: PropTypes.object,
  quizResultsPercentage: PropTypes.number,
};

export default CertificateTab;
