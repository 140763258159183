import awardImg from '../../../assets/images/award.png';

export const wrapper = (theme) => ({
  backgroundColor: theme.newPrimary,
  minHeight: '100%',
  paddingBottom: '1em',
  flex: 1,
  '& .bottomBuffer': {
    marginBottom: 24,
  },
  '@media (max-width:576px)': {
    padding: '2em',
  },
});

export const headerWrap = {
  marginTop: '2em',
  marginBottom: '3.125em',
};

export const heading = (theme) => ({
  color: theme.white,
  fontWeight: 500,
  textTransform: 'capitalize',
});

export const tableWrap = (theme) => ({
  '& .tr': {
    borderBottom: `1px solid ${theme.secondaryLighter}`,
    padding: '0.75em 0',
    '& .th': {
      color: theme.white,
      fontSize: '0.875rem',
    },
  },
  '& .cell-row': {
    borderBottom: `1px solid ${theme.secondaryLighter}`,
    padding: '0.75em 0',
    '& .info-cell': {
      display: 'flex',
      alignItems: 'center',
      '& .avatar': {
        width: 42,
        height: 42,
        borderRadius: 48,
        marginRight: '0.75em',
      },
      '& .friend-info': {
        color: theme.white,
        '& h3': {
          fontSize: '1rem',
        },
        '& p': {
          fontSize: '0.875rem',
        },
      },
    },
    '& .points-cell': {
      '& p': {
        color: theme.white,
        textAlign: 'center',
      },
    },
    '& .badge-cell': {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginLeft: '-0.25em',
      marginRight: '-0.25em',
      '& img': { width: 48, height: 48 },
      '& .badge-wrap': {
        marginLeft: '0.25em',
        marginRight: '0.25em',
      },
    },
    '& .packages-cell': {
      display: 'flex',
      alignItems: 'center',
      '& .package-wrap': {
        marginLeft: '0.5em',
        marginRight: '0.5em',
      },
    },
  },
});

export const numberWrap = (isFirst) => (theme) => ({
  width: 28,
  height: 28,
  backgroundImage: `url(${isFirst ? awardImg : ''})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  marginRight: '0.75em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .number': {
    marginBottom: isFirst ? 8 : 0,
    color: theme.white,
  },
});

export const badgeStyle = (image) => ({
  width: 48,
  height: 48,
  borderRadius: 32,
  backgroundImage: `url(${image})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const packageCircle = (image) => (theme) => ({
  width: 48,
  height: 48,
  borderRadius: 56,
  backgroundColor: image ? '#ffffff00' : theme.whiteGray,
  backgroundImage: `url(${image})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const footerWrap = {
  marginBottom: 50,
  marginTop: 50,
  display: 'flex',
  justifyContent: 'center',
};

export const noData = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.white,
  height: 200,
});

export const packagesTooltipWrap = (widthIndex) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: widthIndex * 88,
  marginLeft: -4,
  marginRight: -4,
  '& .package-tooltip-wrap': {
    width: 80,
    marginLeft: 4,
    marginRight: 4,
    padding: 4,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});

export const badgesTooltipWrap = (widthIndex) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: widthIndex * 68,
  marginLeft: -4,
  marginRight: -4,
  '& .badges-tooltip-wrap': {
    width: 60,
    marginLeft: 4,
    marginRight: 4,
    padding: 4,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});

export const backBtn = (theme) => ({
  padding: 6,
  color: theme.white,
  marginRight: '1em',
  '& i': {
    marginRight: 4,
  },
});
