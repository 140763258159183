export const wrapper = (theme) => ({
  backgroundColor: theme.white,
  borderRadius: 4,
  height: '100%',
  minHeight: 340,
  display: 'flex',
  flexDirection: 'column',
  padding: '0.5em',
  boxShadow: '0px 1px 24px 0px #00000029',
  '&:hover': {
    transform: 'scale(1.03)',
  },
});

export const header = (theme) => ({
  backgroundColor: theme.secondaryLighter,
  minHeight: 180,
  height: 180,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const courseThumb = {
  width: '100%',
  height: 180,
  objectFit: 'cover',
};

export const body = {
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 16px 24px 16px',
  height: '100%',
  justifyContent: 'space-between',
};

export const courseCardHeading = (theme) => ({
  color: theme.newPrimary,
  fontWeight: 500,
  marginBottom: '0.5em',
});

export const percentWrap = (theme) => ({
  '& > .progress': {
    display: 'flex',
    width: '100%',
    backgroundColor: theme.secondaryLighter,
    height: 6,
    borderRadius: 4,
    overflow: 'hidden',
    '& > .progress-bar': {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      backgroundColor: theme.courseProgress,
      height: 6,
      borderRadius: 4,
    },
  },
});

export const percentLabel = (theme) => ({
  display: 'block',
  fontWeight: 300,
  color: theme.grayLighter,
});

export const cardSpansStyles = (theme) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0.5em',
  alignItems: 'center',
  color: theme.grayLighter,
  fontWeight: 300,
  fontSize: 14,
  marginTop: 8,
  '& > i': {
    fontSize: 4,
  },
});
