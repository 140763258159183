export const courseQuizContainer = (theme) => ({
  background: `linear-gradient(180deg, ${theme.newPrimaryGradientStart} 0%, ${theme.newPrimaryGradientEnd} 100%)`,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '4rem',
  color: theme.white,
  textAlign: 'left',
  overflow: 'auto',

  '& .quiz-question': {
    marginBottom: '1em',
  },

  '& .quiz-answer': {
    padding: '1em',
    marginBottom: '0.5em',
    backgroundColor: '#FFFFFF14',
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    textAlign: 'left',
    outline: 'none',

    '&::before': {
      content: "''",
      border: `1px solid ${theme.white}`,
      width: '1rem',
      height: '1rem',
      borderRadius: '50%',
      display: 'inline-flex',
      marginRight: '0.5em',
      flex: '0 0 auto',
    },

    '&:focus:not(:link:active)': {
      outline: 'auto',
    },
  },

  '& .quiz-answer.marked': {
    backgroundColor: theme.courseProgress,

    '&::before': {
      borderWidth: 4,
    },
  },

  '& .quiz-answer.correct': {
    backgroundColor: theme.successDark,
    animation: 'blink-green 0.5s ease-in-out 3',
  },

  '& .quiz-answer.incorrect': {
    backgroundColor: theme.errorDark,
    animation: 'blink-red 0.5s ease-in-out 3',
  },

  '@keyframes blink-green': {
    '0%, 100%': { backgroundColor: theme.successDark },
    '50%': { backgroundColor: 'transparent' },
    '90%': { backgroundColor: theme.successDark },
  },

  '@keyframes blink-red': {
    '0%, 100%': { backgroundColor: theme.errorDark },
    '50%': { backgroundColor: 'transparent' },
    '90%': { backgroundColor: theme.errorDark },
  },
});
