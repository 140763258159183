export const wrapper = {
  '& .bottomBuffer': {
    marginBottom: 24,
  },
  '@media (max-width: 576px)': {
    padding: '0 2em',
  },
};

export const headingLink = (theme) => ({
  color: theme.secondaryDark,
  fontSize: 16,
  lineHeight: '1.5',
  fontWeight: 500,
  transition: 'color .3s linear',
  '&:hover': {
    color: theme.primary,
  },
});

export const dashboardRowStyle = {
  display: 'flex',
  flexDirection: 'column',
};

export const dashboardHeaderStyles = {
  '@media (max-width: 576px)': {
    padding: '0 2em',
  },
};
