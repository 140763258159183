export const wrapper = (theme) => ({
  backgroundColor: theme.newPrimary,
  borderRadius: 4,
  padding: 24,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  border: `1px solid ${theme.newLavender}`,
  boxShadow: '0px 4px 18px 0px #00000029',
});

export const header = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 8,
  '& .title': {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1.5',
    textTransform: 'capitalize',
  },
};

export const body = {
  height: '100%',
};

export const listItemStyle = (theme) => ({
  padding: '8px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1em',
  '& .item-left': {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      fontSize: 12,
      fontWeight: 600,
    },
    '& .avatar': {
      width: 40,
      height: 40,
      borderRadius: 32,
      marginRight: 8,
    },
    '& .name': {
      fontSize: 14,
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
    },
    '& .points': {
      fontSize: 12,
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
    },
    '& .points > .rank': {
      fontSize: 12,
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
      marginLeft: '1em',
    },
  },
  '& .item-right': {
    '& img': {
      width: 48,
      height: 48,
    },
    '& > span': {
      fontSize: 12,
      color: theme.highlightsBlue,
    },
  },
  '& .item-skeleton': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& .skeleton-avatar': {
      marginLeft: 8,
      marginRight: 8,
    },
  },
});

export const noData = {
  display: 'flex',
  flexDirection: 'column',
  gap: '1em',
  justifyContent: 'center',
  height: '100%',
  alignItems: 'center',
  maxWidth: '290px',
  textAlign: 'center',
  '& p': {
    fontWeight: 400,
    fontFamily: 'Roboto, sans-serif;',
    fontSize: '1rem',
  },
};

export const footer = {
  marginTop: 20,
};

export const seeAllStyles = (theme) => ({
  backgroundColor: theme.newPrimaryLighter2,
  color: theme.white,
  border: 'none',
  borderRadius: 34,
  padding: '0.5em 0.75em',
  '&:hover': {
    backgroundColor: theme.newPrimaryBtn,
  },
});
