export const courseBox = (theme) => ({
  backgroundColor: theme.white,
  marginBottom: 40,
  boxShadow: '0px 1px 24px 0px #00000029',
  height: '300px',
  maxHeight: '300px',
  display: 'flex',
  flexDirection: 'column',
  '& .card-content': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  '& .img-wrapper': {
    padding: '1em',
    flex: '0 0 auto',
    width: '100%',
    height: '150px',
    overflow: 'hidden',
    marginBottom: '1em',
    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  },
  '& .course-text-box': {
    flex: '1 1 auto',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0 1em 1em 1em',
    maxWidth: '100%',
    maxHeight: '100%',
    '& .title': {
      fontSize: '0.875rem',
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      color: theme.newPrimary,
    },
    '& .text': {
      fontSize: '0.75rem',
      fontWeight: 400,
      color: theme.newGray6,
      wordBreak: 'break-word',
      lineHeight: '1.5',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
});

export const buttonStyle = (theme) => ({
  padding: '0.5em 0.75em',
  color: theme.white,
  backgroundColor: theme.newPrimary,
  borderRadius: 4,
  fontSize: '0.75rem',
  lineHeight: '1.1375rem',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

export const skeletonStyle = {
  marginBottom: 40,
};

export const cardWrapper = {
  display: 'flex',
};

export const btnAndDurationCourseCard = (theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '1em',
  width: '100%',
  '& p': {
    fontSize: '0.6875rem',
    color: theme.newGray7,
  },
  '& a:hover': {
    transform: 'scale(1.05)',
  },
});
