export const certificateTabWrapper = {
  '& h6': {
    marginBottom: '1em',
  },
  '& iframe': {
    maxWidth: '100%',
    height: 'auto',
  },
};

export const certificateBtn = {
  padding: 0,
  position: 'relative',
  '& .overlay': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    cursor: 'zoom-in',
  },
};

export const pdfViewerStyles = {
  width: '300px',
  height: '212px',
  backgroundColor: '#fff',
  border: 'none',
};

export const pdfViewerModalStyles = {
  width: '792px',
  height: '560px',
  backgroundColor: '#fff',
  border: 'none',
};

export const modalStyles = (theme) => ({
  '& section': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '786px',
  },
  '& .downloadBtn-wrapper': {
    background: theme.newBackgroundLight,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '1em 0',
  },
  '& button': {
    alignItems: 'baseline !important',
  },
  '& img': {
    width: 16,
    height: 16,
    marginRight: '0.5em',
  },
});
