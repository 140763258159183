import { keyframes } from '@emotion/react';

const rotate = keyframes`
    100% {
        transform: rotate(1turn);
    }
`;

const dash = keyframes`
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -135px;
    }
`;

export const svgLoader = {
  outline: '0 !important',
  width: 120,
  zIndex: 100,
  position: 'absolute',
  top: '50%',
  left: '50%',
  background: 'transparent',
  transform: 'translate(-50%, -50%)',
  transition: 'opacity .1s, transform .25scubic-bezier(.17,.88,.32,1.28)',
  color: '#fff',

  '& .loader-background': {
    strokeOpacity: '0.2',
    strokeWidth: '2px',
    background: 'transparent',
  },

  '& .loader-circle': {
    transformOrigin: '50% 50%',
    strokeLinecap: 'round',
    strokeDasharray: '200',
    strokeDashoffset: '200',
    strokeWidth: '2px',
    background: 'transparent',
    animation: `${rotate} 2s linear .25s infinite, ${dash} 1.5s ease-in-out .25s infinite`,
  },
};

export const pdfLoaderContainer = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const writeDown = keyframes`
  0% { height: 0%; opacity: 0;}
  20%{ height: 0%; opacity: 1;}
  80% { height: 65%; opacity: 1;}
  100% { height: 65%; opacity: 0;}
`;

export const pdfLoader = (theme) => ({
  position: 'relative',
  width: '10rem',
  height: '15rem',
  background: 'rgba(255, 255, 255, 0.87)',
  borderRadius: '4px',
  display: 'block',
  '&:before': {
    content: '""',
    position: 'absolute',
    width: '4rem',
    height: '0.5rem',
    left: '50%',
    top: 0,
    backgroundColor: theme.newPrimary,
    transform: 'translate(-50%, 0)',
    boxShadow: '0 -3px rgba(0, 0, 0, 0.25) inset',
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    left: '50%',
    top: '20%',
    transform: 'translateX(-50%)',
    width: '66%',
    height: '60%',
    background: `linear-gradient(to bottom, ${theme.newPrimary} 30%, #0000 31%)`,
    backgroundSize: '100% 16px',
    animation: `${writeDown} 2s ease-out infinite`,
  },
});

export const quizResults = (theme) => ({
  background: `linear-gradient(180deg, ${theme.newPrimaryGradientStart} 0%, ${theme.newPrimaryGradientEnd} 100%)`,
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 100,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '2rem',
  padding: '4rem',
  color: theme.white,

  '& .quiz-results-image': {
    maxWidth: '12rem',
  },

  '& .retake-button': {
    color: theme.white,
    borderColor: theme.white,
  },
});
