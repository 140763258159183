export const wrapper = (theme) => ({
  color: theme.white,
  '& .bottomBuffer': {
    marginBottom: 16,
  },
});

export const myCoursesHeaderWrap = (theme) => ({
  backgroundColor: theme.newPrimary,
  '@media (max-width: 576px)': {
    padding: '0 2em',
  },
});

export const bigHeading = (theme) => ({
  '& > h3': {
    color: theme.white,
    fontSize: '2rem',
    fontWeight: 500,
  },
});

export const heading = (theme) => ({
  marginBottom: '2em',
  color: theme.newLavender,
  fontWeight: 300,
  fontFamily: 'Roboto, sans-serif;',
  fontSize: '0.75rem',
  padding: '-1px 0.75em',
});

export const noData = {
  width: '100%',
  height: 'auto',
  display: 'flex',
  justifyContent: 'center',
};

export const filterSearchDropdown = (theme) => ({
  maxWidth: 144,
  '& > div:first-of-type': {
    padding: '0.25rem 0.75rem',
    border: `solid 1px ${theme.newGray5}`,
    fontSize: 12,
    color: theme.newGray0,
    backgroundColor: theme.newBackgroundLight,
    borderRadius: 36,
  },
  '@media (max-width: 576px)': {
    marginBottom: '1em',
  },
});

export const filterAndHeadingWrapper = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1em',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  '@media (max-width: 576px)': {
    padding: '0 1.5em',
  },
};
