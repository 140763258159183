import { keyframes } from '@emotion/react';

export const coursePlayerContainer = (theme) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',

  '& .las': {
    position: 'absolute',
    top: '50%',
    transform: 'transitionY(-50%)',
    padding: '0.5em',
    borderRadius: '50%',
    backgroundColor: theme.white,
  },

  '& .la-chevron-left': {
    left: 10,
  },

  '& .la-chevron-right': {
    right: 10,
  },
});

export const playerArrowControls = (theme) => ({
  '&:hover': {
    color: theme.white,
    backgroundColor: theme.courseProgress,
    transform: 'scale(1.05)',
    zIndex: 999,
  },
});

const prixClipFix = keyframes`
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
`;

export const loadingWrapper = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.8)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const loaderSpinnerNextVideo = (theme) => ({
  width: 64,
  height: 64,
  border: `10px solid ${theme.white}`,
  borderRadius: '50%',
  transform: 'rotate(45deg)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  position: 'relative',

  '&:before': {
    content: '""',
    position: 'absolute',
    inset: '-10px',
    borderRadius: '50%',
    border: `10px solid ${theme.courseProgress}`,
    animation: `${prixClipFix} 2s infinite linear`,
  },

  '& .play-button': {
    width: 0,
    height: 0,
    borderTop: '8px solid transparent',
    borderBottom: '8px solid transparent',
    borderLeft: `12px solid ${theme.courseProgress}`,
    transform: 'rotate(-45deg)',
    position: 'absolute',
    zIndex: 2,
  },

  '& .next-video': {
    fontSize: 24,
    color: theme.white,
    whiteSpace: 'nowrap',
    marginTop: 10,
    transform: 'rotate(-45deg)',
  },
});
