export const howDoesItWorkWrapper = (theme) => ({
  backgroundColor: theme.newPrimary,
  color: theme.white,
  padding: '3em 0',

  '& h6': {
    fontSize: '1.25rem',
    fontWeight: 600,
    marginBottom: '1em',
  },

  '& p': {
    fontSize: '0.875rem',
    fontWeight: 400,
    marginBottom: '1em',
    width: '100%',
  },

  '& ul': {
    width: '100%',
    marginBottom: '1em',

    '& li': {
      marginBottom: '0.5em',
    },
  },
});

export const backBtn = (theme) => ({
  padding: 6,
  color: theme.white,
  marginBottom: '1em',

  '& i': {
    marginRight: 4,
  },
});
