export const courseDetails = (theme) => ({
  color: theme.white,
  '& .details-wrapper': {
    display: 'flex',
    gap: '2em',
    justifyContent: 'space-between',
    width: '100%',
  },
  '& .header-left, .header-right': {
    flex: '0 0 50%',
  },
  '& .header-right': {
    padding: '1.5em',
    paddingRight: '2em',
    '& img': {
      width: '100%',
    },
  },
  '& .details-title, .details-info': {
    marginBottom: '1em',
  },
  '& .info-and-button': {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1em',
    alignItems: 'center',
    margin: '1em 0',
    paddingBottom: '1em',
  },
  '@media (max-width: 576px)': {
    padding: '1.5em',
    '& .details-wrapper': {
      flexDirection: 'column',
    },
    '& .info-and-button': {
      flexDirection: 'column',
      '& p': {
        textAlign: 'center',
      },
    },
  },
});

export const startNowBtn = (theme) => ({
  backgroundColor: theme.newPrimaryBtn,
  padding: '0.625em 1em',
  borderRadius: 4,
  '&:hover': {
    backgroundColor: theme.warning,
  },
});

export const iconAndText = (theme) => ({
  display: 'flex',
  gap: '0.5em',
  padding: '1em 0',
  borderTop: '1px solid #FFFFFF1F',
  alignItems: 'center',

  ':first-of-type': {
    borderTop: 'none',
  },

  '& i, svg': {
    color: theme.white,
    width: 24,
    height: 24,
  },
  '& svg': {
    color: theme.white,
    width: 20,
    height: 20,
  },
  '& ul': {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
});

export const includesWrapper = (theme) => ({
  fontFamily: 'Roboto, sans-serif;',
  fontWeight: 300,
  marginTop: '4em',
  '& h6': {
    fontWeight: 500,
  },
  padding: '1.5em',
  border: `1px solid ${theme.courseProgress}`,
  borderRadius: 4,
  boxShadow: '0px 4px 18px 0px #00000029',
});

export const heading = (theme) => ({
  color: theme.newLavender,
  fontWeight: 300,
  fontFamily: 'Roboto, sans-serif;',
});

export const breadcrumbStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '0.5em',
  marginBottom: '1em',
};
