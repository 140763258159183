export const videoItemWrap = (isActive) => (theme) => ({
  backgroundColor: isActive ? theme.courseProgress : 'transparent',
  display: 'flex',
  alignItems: 'center',
  opacity: 1,

  '&:hover': {
    backgroundColor: isActive ? theme.courseProgress : theme.newLavender,
  },

  '& .icon': {
    marginRight: '1.5em',
    opacity: isActive ? 1 : 0.8,
    width: 16,

    '& > i': {
      fontSize: '1rem',
    },
  },

  '& .course-curriculum-item': {
    color: isActive ? theme.white : theme.newGray3,
    border: 'none',
    whiteSpace: 'normal',
    width: '100%',
    opacity: isActive ? 1 : 0.8,
    lineHeight: '1.2',
    textAlign: 'left',

    '&:hover': {
      border: 'none',
    },

    '&:active, &:focus': {
      color: isActive ? theme.white : theme.newGray3,
      border: 'none',
    },

    '& path': isActive ? { stroke: theme.white } : {},
  },
});

export const asideStyle = (theme) => ({
  width: 'clamp(15rem, 30vw, 30rem)',
  flex: '0 0 auto',
  backgroundColor: theme.white,
  minHeight: 'calc(80vh - 64px)',
  borderLeft: `1px solid ${theme.newGray4}`,

  '& .course-title': {
    fontSize: '1.25rem',
    fontWeight: 400,
    padding: '0.5em 1em',
  },

  '& .course-curriculum-list': {
    padding: 0,
    margin: 0,
  },

  '& .course-curriculum-item': {
    justifyContent: 'space-between',

    '& .item-icon-and-text': {
      display: 'flex',
      alignItems: 'center',
      marginRight: '1em',
    },

    '& .item-duration': {
      fontSize: '0.875rem',
      whiteSpace: 'nowrap',
    },
  },

  '& i': {
    marginRight: '0.5em',
  },

  '@media (max-width: 768px)': {
    width: '100%',
  },
});

export const percentWrap = (theme) => ({
  padding: '1.5em',
  '& > .progress': {
    display: 'flex',
    width: '100%',
    backgroundColor: theme.secondaryLighter,
    height: 6,
    borderRadius: 4,
    overflow: 'hidden',

    '& > .progress-bar': {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      backgroundColor: theme.courseProgress,
      height: 6,
      borderRadius: 4,
      transition: 'width 1s ease-in-out',
    },
  },
});

export const percentLabel = (theme) => ({
  padding: '1em 0',
  display: 'block',
  fontWeight: 300,
  color: theme.grayLighter,
});
