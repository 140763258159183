export const resourceList = (theme) => ({
  '& .course-material-container': {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    padding: '2em',
    borderBottom: `1px solid ${theme.newGray4}`,
    gap: '0.5em',

    '&:last-of-type': {
      borderBottom: 'none',
    },

    '& .course-material-icon': {
      maxWidth: '3rem',
      maxHeight: '3rem',
      marginRight: '1em',
    },
  },
});
