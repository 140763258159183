export const pageWrapper = (theme) => ({
  padding: '3em 0',
  color: theme.white,
  backgroundColor: theme.newPrimary,

  '& h1': {
    textAlign: 'center',
    fontSize: '2rem',
    marginBottom: '0.5em',
  },

  '& h2, & h3': {
    fontSize: '1.5rem',
    textAlign: 'center',
    marginBottom: '0.5em',
  },

  '& p': {
    marginBottom: '1em',
  },

  '& .content': {
    display: 'block',
  },
});

export const backBtn = (theme) => ({
  padding: 6,
  color: theme.white,

  '& i': {
    marginRight: 4,
  },
});
